import React, {useState, useEffect} from 'react'
import './Reservations.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link, Redirect, withRouter} from 'react-router-dom'
import { useForm } from "react-hook-form";
import axios from 'axios';
import ReservationsDateViewer from './ReservationsDateViewer'

// import slots from './slots'


// 5:00pm, 5:15pm




function Reservation ({history, match}) {
  const { register, handleSubmit, watch, errors } = useForm();
  const [errorMsg, setErrorMsg] = useState('')
  const [slots, setSlots]= useState([])
  const [appState, setAppState]= useState('SLOTS')
  const [selectedSlotId, setSelectedSlotId]= useState(false)
  const params = match.params
  // const history = useHistory()
  // const [email, setEmail] = useState('')
  // const [phone, setPhone] = useState('')
  // const [partySize, setPartySize] = useState('')
  // const [notes, setNotes] = useState('')

  function fetchReservations () {
    fetch('https://llama-secret-reserve.glitch.me/available-3/mikaza')
      .then((res) => res.json())
      .then((days) => {

        setSlots(days)
      })
  }

  useEffect(fetchReservations, [0])


  const onSubmit = data => {
    console.log(data)
    console.log(selectedSlotId)
    const submission = Object.assign({}, data, {slotId: selectedSlotId, shopId: 'mikaza'})
    axios.post('https://llama-secret-reserve.glitch.me/reserve-2', submission)
      .then((response) => {
        console.log(response.data)
        const {success, message, slotId: id, name} = (response.data)
        if (!success) {
          fetchReservations()
          setErrorMsg(message)
        } else {
          // success
          history.push(`/reserve/${id}/${name}`)
        }

      })

  };

  if (params.name) {
    const {name, id} = params
    const [idAndYear, month, day, dateAndTime, timezone] = id.split('-')
    return(
      <div className="page">
        <div className="reservations-panel">
          <div className="confirmation">
            <h1>Reservation Confirmation</h1>
            <p>{name}, Your reservation is confirmed for:</p>
            <p>{month}-{day} {dateAndTime} {timezone}</p>
            <p>to correct a reservation you may email mikaza@hidethellama.com</p>
            {/* <p>{id}</p> */}
            {/* <p>{name}</p> */}
          </div>
        </div>
      </div>
    )
  }



  return(
    <div className="page">

      <div className="reservations-panel">
        <div className="header">
          <h1>Reservations</h1>
  
          <p>NOTE: Please arrive no later than 15min after reserved time, we can only hold table for maximum of 15min past your reservation time</p>

          {/* <p>First come first serve seating also available. join our <Link to="/order">mailing list</Link> for future reservations</p> */}

        </div>
        
        {(true) && 
          <form 
            onSubmit={handleSubmit(onSubmit)} 
            className="info">
            <p>
              <label><span className="text">Name: </span><input   
                  type="text" 
                  name="name"
                  ref={register}
                />
              </label>
            </p> 
            <p>
              <label><span className="text">Email: </span><input   
                  type="email" 
                  name="email"
                  ref={register}
                />
              </label>
            </p> 
            <p>
              <label><span className="text">Phone: </span><input   
                  type="text" 
                  name="phone"
                  ref={register}
                />
              </label>
            </p> 
            <p>
              <label><span className="text">Party Size: </span><input   
                  type="text" 
                  name="partySize"
                  ref={register}
                />
              </label>
            </p> 


            {(appState === 'SLOTS') && 
              <ReservationsDateViewer 
                slots={slots}
                setSelectedSlotId={setSelectedSlotId}
                selectedSlotId={selectedSlotId}
              ></ReservationsDateViewer>
            }

            

            <div className="submit-wrapper">
            {errorMsg && 
              <div className="error-message">
                {errorMsg}
              </div>
            }
            	<input 
            	  className="submit-button"
            	  value="reserve"
            	  type="submit"/>
            </div>

            
          </form>
        } 




      </div>

      
    </div>
  )
}

export default withRouter(Reservation)