import React, {useState} from 'react'

export default function AutoMenuNavigator ({subMenus}) {
  
  const [expanded, setExpanded] = useState(false)
  return(null)
  return(
    <div className="menu-navigation">
      <nav
        className="menu-navigation-links"
        role="navigation"
        aria-label="Menu Categories">
        {subMenus.map(({name, description, disableOrder, id}) => {
          const anchorLink = `#menu-category-`
          return(
            <a
              className="category-link"
              href={anchorLink}>{name && name.en}</a>
          )
        })}
      </nav>
    </div>
  )
}
