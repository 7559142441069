import React, {useContext} from 'react'
import AutoMenu from './AutoMenu'
import {Link} from 'react-router-dom'
import Banner from './banner'
import LogoBlend from './svg/LogoBlend'
import LogoBar from './svg/LogoBar'
import Stout from './stout'
import Instagram from './icons/Instagram'
import Facebook from './icons/Facebook'
import Twitter from './icons/Twitter'
import './LandingPage.scss'
import Form from './Form'
import './WhatsNew.scss'
import ThemeContext from '../../Context/ThemeContext'
import FormReserve from './FormReserve'
import {Helmet} from 'react-helmet'


const youtubeReview = "https://www.youtube.com/embed/NeZIUbKfKPE"


const menuLinks = {
  walnut: [
    {name: "Family Meals TO GO",      link: '/public/walnut-creek/v4-family.pdf'},
    {name: "Meals Instructions",      link: '/public/walnut-creek/bierhaus-family-togo-instructions.pdf'},
    // {name: "Lunch Menu",      link: '/public/walnut-creek/v17%20WC%20Bierhaus%20Lunch%20Menu.pdf'},
    // {name: "Dinner Menu",     link: '/public/walnut-creek/v17%20WC%20Bierhaus%20Dinner%20Menu.pdf'},
    // // {name: "Happy Hour Menu", link: '/public/walnut-creek/v2%20WC%20Happy%20Hour%20Menu.pdf'},
    // {name: "Bier/Wine Menu",  link: '/public/walnut-creek/v16%20WC%20Beer%20Menu.pdf'},
    {name: "Beers TO GO",  link: '/public/walnut-creek/v3-Bottle-Menu.pdf'}
  ],
  oakland: [
    // {name: "Daily Menu",  link: '/public/oakland/v6-OAK-Limited-Bierhaus-Food-Menu.pdf'},
    // {name: "Happy Hour Menu",  link: '/public/oakland/L4%20Happy%20Hour%20Menu.pdf'},
    // {name: "Bier/Wine Menu",  link: '/public/oakland/v21-OAK-Beer-Menu.pdf'}
  ]
}

const gallery = [
  // {
  //   alt: '',
  //   url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-team.jpg'
  // },

  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-1.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-2.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-3.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-4.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-5.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-6.png'
  },

  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-team-1.jpg'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-team-2.jpg'
  },
]

export default function LandingPage () {
  const {themeName} = useContext(ThemeContext)
  const logoURL = (themeName === 'dark') ?
  "https://afag.imgix.net/ten-ramen/logo-bar.png?w=900&auto=format&invert=true" :
  "https://afag.imgix.net/ten-ramen/logo-bar.png?w=900&auto=format"
  return(
    <div className="landing-page">
      <Helmet>
        <title>Mikaza Express</title>
        <meta name="description" content="Peruvian Japanese fusion sushi and bento box. located inside Pablito's World at 686 N Spring St" />
      </Helmet>
      <div className="header">
        <div className="hero-wrapper">
          <div className="hero-banner">

            <div>
              {/* <p
                style={{
                  color: 'white',
                  textShade: "2px -2px 1px black",
                  fontWeight: 800
                }}
                >Order Curbside / Pickup / Delivery</p> */}
              {/* <a href="http://toasttab.com/bierhaus-walnut-creek" className="order-online-button">Order Pickup</a> */}
              {/* <Link to="/order" className="order-online-button">
              Order Pickup / Delivery
              <br/>
              <div
                style={{
                  fontSize: "0.8em",
                  marginTop: "0.2em",
                  textAlign: "center"
                }}
                className="small">10% Cashback</div>
            </Link> */}
              {/* <Link to="/menu" className="order-online-button">
              View Menu
              
            </Link>
              <Link to="/reserve" className="order-online-button">
              Reserve
              
            </Link> */}

            {/* <div className="grand-opening">
              <img 
              alt="Bento Box"
              src="https://afag.imgix.net/pablitos-world/nikkei.jpg?w=1200&auto=format" alt="" />
            </div> */}


            <a href="https://pablitosworld.com/order/pab-mikaza" className="order-online-button">
              Order Pickup / Delivery
              
            </a>

            </div>
          </div>
          <div className="logo-banner">
            {/* <img src={logoURL}
             alt="Ten Ramen"
             className="hero-logo"
           /> */}
            <LogoBlend
              themeName={themeName}
              className="hero-logo"
              ></LogoBlend>


          </div>
        </div>


      </div>

      <div className="content">

        {/* <p
          style={{
            textAlign: 'center',
            margin: '1.5em 0.5em',
            fontWeight: "600",

          }}
          className="notice">NOTICE: We may have to close early afternoon so get in your order now</p> */}

        <div className="content-grid locations">
          <article className="location walnut">
            <div className="location-header">
              <h2 className="location-name">Los Angeles</h2>

              <p className="subtitle">inside Pablito's World</p>

              <p className="address">
                <a className="address-link"
                  href="https://www.google.com/maps/place/686+N+Spring+St,+Los+Angeles,+CA+90012/@34.0595704,-118.239826,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2c65b295a77cf:0x12a13c1c389204b!8m2!3d34.0595704!4d-118.237632">
                  686 N Spring St
                </a></p>
              <p className="phone">
                 <a
                   style={{fontWeight: 600}}
                   href="tel:+12133725898">(213) 372-5898</a>
              </p>



              <div className="hours">
                <h3 className="hours-title">Hours</h3>
                <p>Mon-Thurs</p>
                <p>11am - 9pm</p>
                <p>Fri,Sat</p>
                <p>11am - 3am</p>
                <p>Sunday</p>
                <p>11am-10pm</p>
                {/* <p>Due to renovations, a small menu is available</p> */}


                {/* <p>Brunch menu on Fri, Sat, Sun before 3pm</p>
               
  
                <p><strong>Tue-Thu:</strong> 5pm - 10pm</p>
                <p><strong>Friday Brunch:</strong> 10am - 3pm</p>
                <p><strong>Friday Dinner:</strong> 5pm - 11pm</p>
                <p><strong>Saturday Brunch:</strong> 10am - 3pm</p>
                <p><strong>Saturday Dinner:</strong> 5pm - 11pm</p>
                
                <p><strong>Sunday Brunch:</strong> 10am - 3pm </p>
            

                <p>Last Call: 30min before close</p> */}
                

                {/* <p>dine-in only</p> */}
                {/* <p>Get 15% off your order by joining our mailing list now.</p> */}
                {/* <Link to="/order" className="order-online-button">Order Curbside / Pickup / Delivery</Link> */}

                {/* <p>Sunday Closed</p> */}
              </div>
            </div>


          </article>






        </div>


      </div>

      <div className="whats-new">

       


        {/* <div className="in-the-news">
  
          <div className="article">
            <img 
            alt="LA Taco: A patriarchy-defying Latina sushi chef, a chef from argentina, and a peruvian taquero brings DTLA's first full Nikkei restaurant"
            src="https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/la-tacos-review.png?w=800&auto=format" alt=""/>
            <a href="https://www.lataco.com/nikkei-dtla-japanese-peruvian/">read review</a>
          </div>

        </div> */}

        {/* <FormReserve/> */}


        {/* <Form></Form> */}

        {/* <div className="youtube-vid-wrapper">
          <iframe
          className="youtube-vid"
          width="560"
          height="315"
          src={youtubeReview}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          ></iframe>
      </div>
 */}

        {/* <div className="gallery">
          {gallery.map(({url, alt}) => {
            return (
              <img
               alt="sample food photo of Mikaza, menu coming later"
               className={"gallery-img"}
               src={`${url}?fit=crop&w=400&h=400`} alt=""/>
            )
          })}
        </div> */}

{/* 
        <div className="whats-new__content">

          <article className="full-spread slow cooked">

            <div className="main">
              <img src="https://afag.imgix.net/bierhaus/ribs-close-up.jpg?w=1200&auto=format" alt=""/>
            </div>

            <div className="text-box-wrapper">
                <div className="text-box">
                <h2>Slow-Cooked</h2>
                <p>Available warm or as reheat-at-home meal kits.</p>
              </div>
            </div>

          </article>



        </div> */}
{/*
        <div className="link-wrapper">
          <a href="/order?bottom" className="order-online-button">View Full Menu</a>
        </div> */}

      </div>

      <div className="menu">
        {/* <div className="menu-header">
          <h2>Our Menu</h2>
        </div> */}

        <AutoMenu 
        tagsToShow={['pab-mikaza']}
        subMenuFilter={subMenuFilter}
        disableOrder={true}
        width={600}></AutoMenu>

        {/* <div className="beverage-menu-link-wrapper">
          <Link to="/drinks" className="order-online-button">Full Beverage Menu</Link>
        </div> */}
      </div>



      {/* <div className="content">
        <div className="content-grid">
          <div className="email-form">
            <EmailSubscribeForm></EmailSubscribeForm>
          </div>


          <div className="social-links">
            <a
              className="social-link"
              href="https://www.instagram.com/bierhausca/"><Instagram></Instagram>Instagram</a>
            <a
              className="social-link"
              href="https://www.facebook.com/bierhausca/"><Facebook></Facebook>Facebook</a>
            <a
              className="social-link"
              href="https://twitter.com/BierhausCA"><Twitter></Twitter>Twitter</a>
          </div>
        </div>
      </div> */}






      {/* <div className="landing-page-footer">
        <Stout></Stout>
      </div> */}
    </div>
  )
}


export function subMenuFilter (subMenu) {

  return true
  const tag = subMenu.tag || ''
  // if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekend = [0,5,6].indexOf(day) !== -1
  // const isLunch = (
  //   (hours >= 11) && (hours < 22)
  // )
  // const isBreakfast = (
  //   (hours < 15)
  // )

  const isLateNightHappyHour =     tag.indexOf('late-happy-hour') !== -1 
  const isBrunch =     tag.indexOf('brunch') !== -1 
  const isBrunchOnly =     tag.indexOf('brunch-only') !== -1 
  const isSalsaNightOnly =     tag.indexOf('salsa-night-only') !== -1 
  const isSalsaNight =     tag.indexOf('salsa-night') !== -1 
  const isBar = tag.indexOf('bar') !== -1 
  const isAllDayHappy = tag.indexOf('all-day-happy') !== -1 
  // console.log('dsdfs', day, hours)
  if (isAllDayHappy) {
    return true
  } else {
    return false
  }
  if (((day === 4) && (hours >= 22)) || 
      ((day === 5) && (hours <= 2))
  ) {
    if (isSalsaNightOnly || isSalsaNight) {
      return true
    } else {
      return false
    }
  }

  // console.o

  if (isWeekend && (hours < 15)) {
    if (isBrunch || isBar) {
      return true
    } else {
      return false
    }
  }

  if (isBrunchOnly) {
    return false
  }

  if (isSalsaNightOnly) {
    return false
  }

  // if ((hours >= 21) && (minutes > 30)) {
  //   if (isLateNightHappyHour) {
  //     return true
  //   } 
  //   return false
  // } else if ((hours >= 22)) {
  //   if (isLateNightHappyHour) {
  //     return true
  //   } 
  //   return false
  // } else {
  //   return true
  // }

  

  if (isLateNightHappyHour) {
    // console.log('breakfast', hours)
  
  }
 

  return true
}
