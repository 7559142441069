import React, {useState, useEffect} from 'react'
import './Reservations.scss'
import {Link, Redirect, withRouter} from 'react-router-dom'

export default function ReservationsDateViewer ({slots, setSelectedSlotId, selectedSlotId}) {
  const [dateIndex, setDateIndex] = useState(0)
  if (slots.length < 1) {
    return(
      <div className="days">
        <p>🍣🍣🍣 Loading Available Reservations...</p>
      </div>
    )
  }
  return (
    <div className="days">
      <div className="slotNav">
        {slots.map(({slots, year, day, month, dayOfWeek}, index) => {
          const classNameTag = (index === dateIndex) ? ' selected' : ''
          return(
            <a 
            className={"slot-date-nav-link" + classNameTag}
            onClick={(e) => {
              e.preventDefault()
              setDateIndex(index)
            }}
            href={`#date-${year}-${month}-${day}`}>{month}-{day} {dayOfWeek}</a>
          )
        })}
      </div>
      {[slots[dateIndex]].map(({slots, year, day, month, dayOfWeek}) =>  {
        if (slots.length < 1) {
          return null
        }
        return(
          <div className="day">
            <div className="">
              <h2 className="date-title" id={`date-${year}-${month}-${day}`}>
                {month}-{day} {dayOfWeek}
              </h2>
            </div>
            <div className="slots">
              {
                slots.map(({id: slotId, month, day, hour, minute, reserved, available}) => {
                  const display = `${hour - 12}:${minute}pm`
                  const selected = (selectedSlotId === slotId)
                  let className = selected ? "selected slot" : "slot"
                  if (reserved) {className = 'reserved slot'}
                  return (
                    <div className="slot-wrapper">
                      <div key={slotId} className={className}>
                        <input 
                          name="slotId"
                          value={slotId}
                          id={slotId} 
                          checked={selected}
                          type="radio"
                          disabled={reserved}
                          onChange={(event) => setSelectedSlotId(event.target.checked ? slotId : false)}
                        />
                        <label
                          htmlFor={slotId}
                        >
                          {/* <div className="date">{month}.{day}</div> */}
                          <div className="time">{display}</div>
                          {!reserved && <div className="available">{available} spots</div>}
                        
                        </label>   
                      </div>
                    </div>
                  )
                  })
              }
            </div>
          </div>
        )
      })
      }
    </div>
  )
}