
import * as Components from "./Mikazaexpress"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "mikazaexpress"
}

