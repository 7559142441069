import React from 'react';
import { Provider, Subscribe, Container } from 'unstated';

import isOrderingOnline from './isOrderingOnline'
import calcItemPrice from './calcItemPrice'
import calcItemPriceArray from './calcItemPriceArray'
import {round} from 'lodash'
import logEvent from '../utils/logEvent'
import calcPromoDiscount from '../Cart/calcPromoDiscount'


import getEndpoint from '../utils/getEndpoint'
let ORDERS_END_POINT = getEndpoint('orders')

const CASHBACK_ENDPOINT = getEndpoint('get-cashback')

console.log('orders endpoint:', ORDERS_END_POINT)

function pick(obj, keys) {
    return keys.map(k => k in obj ? {[k]: obj[k]} : {})
               .reduce((res, o) => Object.assign(res, o), {});
}


class CartContainer extends Container {
  constructor (props = {}) {
    console.log('🚀 INITIALIZING CART')
    super()
    if (!props.shopId) {
      return null
    }
    this.shopId = props.shopId
    this.settings = props.settings
    console.log('cart container props: ', props)
    const defaultState = {
      products: [], //{ ...productid, info, selections: {} }
      cartType: 'PICKUP',
      paymentType: 'CASH',
      deliveryAddress: null,
      card: null,
      appliedCashback: 0,
      name: '',
      phone: '',
      pickupTime: null,
      deliveryTime: null,
      distanceInMiles: null,
      deliveryFeeInCents: 0,
      isDeliverable: null,
      unitNumber: '', deliveryInstructions: '',
      analytics: {},
      initialized: true,
      preTipPrimaryCents: null,
      restaurantDonationCents: 199,
      agreeToDonate: null,
      promoCode: null,
      dineInOption: null,
      tabNumber: '',
      enableSecretDineIn: false,
      utensilsOption: false,
    }
    // load state from local storage or initialize new state
    this.state = defaultState

    this.getStorageName = () => {
      // let restId = document.querySelector('#restId').dataset['rid']
      console.log(`afa-cart-5-${this.shopId}`)
      return `afa-cart-14-${this.shopId}`
    }

    this.tryFetchAccount = async ({phone}) => {
      if (phone && phone.length < 10) { return }
      try {
        const url = `${CASHBACK_ENDPOINT}?r_id=${this.shopId}&phone=${phone}`
        console.log(url)
        const response = await fetch(url)
        const {text:{cashback}} = await response.json()
        console.log('cashback:', cashback)
        this.setState({ cashback })
      } catch (e) {
        console.log(e)
      }
    }




    this.saveQueryString = async () => {

      try {
        const queryStirng = window.location.search || ""
        console.log('saving query ', queryStirng)
        if (queryStirng.indexOf('llama') !== -1) {
          const affiliate = queryStirng
          await this.setState({ analytics: Object.assign({}, analytics, {affiliate}) })
        }
        // save query string
        let analytics = this.state.analytics || {}
        let queryStringHistory = analytics.queryStringHistory || []
        if (queryStringHistory.length < 20) {
          queryStringHistory.push({ ts: Date.now(), qs: queryStirng })
          await this.setState({ analytics: Object.assign({}, analytics, {queryStringHistory}) })
          this.saveCart()
        }
      } catch (e) {
        console.log(e)
      }
    }

    this.enforceRulesAndSave = async () => {
      const settings = props.settings
      if ((this.state.cartType === 'DELIVERY') &&
        (this.state.paymentType === 'CASH') &&
        settings &&
        settings.delivery &&
        settings.delivery.deliveryCreditOnly) {
          console.log('🙈enforcing rule')
          await this.setState({ paymentType: 'CARD' });
      }
      this.saveCart()
    }

    this.setCartType = async (cartType) => {
      await this.setState({ cartType });
      this.enforceRulesAndSave()
    }
    this.setStateAndSave = async (stateObj) => {
      // console.log('🐸', stateObj)
      await this.setState(stateObj)
      this.saveCart()
    }

    this.saveCart = () => {
      console.log('saving cart')
      try {
        window.localStorage[this.getStorageName()] = JSON.stringify(
          pick(this.state, ['products', 'cartType', 'deliveryAddress', 'addressComponents', 'unitNumber', 'deliveryInstructions', 'name', 'phone', 'paymentType', 'analytics', 'pickupTime', 'deliveryTime', 'promoCode', 'dineInOption', 'enableSecretDineIn', 'utensilsOption'])
        )
      } catch (e) {
        console.log('save localstorage error')
        try {window.Sentry.captureException(e)} catch (e) {}
      }
    }
    this.clearCart = async () => {
      // this.setState((prevState) => ({ products: prevState.products.push() }))
      await this.setState({ products: [], appliedCashback: 0, analytics: {}, promoCode: '' })
      this.saveCart()
    }
    this.addToCart = async (product, cartType) => {
      let cartItemId = Date.now()
      let itemToSave = Object.assign({}, product,
        {id: cartItemId, productId: product.id, quantity: 1 })
      // let itemToSave = (({name, photo, shoId, type, id, customerPicks, modgroups, description, code}) => ({name, photo, shoId, type, id, customerPicks, modgroups, description, code}))(itemToSave)
      let newState = { card: cartItemId, cartType: cartType || this.state.cartType, products: [...this.state.products, itemToSave] }
      await this.setState(newState)
      this.saveCart()
      logEvent(`add/${cartItemId}`, product.name && product.name.en)
      return(cartItemId)
    }
    global.__addToCart = this.addToCart
    this.addProduct = async (product) => {
      await this.setState({ products: [...this.state.products, product] })
      this.saveCart()
    }
    this.saveProduct = async (product) => {
      let newState = { products: this.state.products.map((p) => {
        if (p.id === product.id) {
          return product
        } else {
          return p
        }
      }) }
      await this.setState(newState)
      this.saveCart()
    }
    this.applyCashback = (cashback) => {
      const {invoice: totalPrice} = this.getSubtotals() // totalPrice refers to before-tax subtotals
      const totalPriceInCents = totalPrice * 100
      // console.log(cashback, totalPrice)
      if (totalPriceInCents > cashback) {
        this.setState({appliedCashback: cashback})
      } else {
        this.setState({appliedCashback: totalPriceInCents})
      }
    }
    this.createOrder = (options, callback) => {
      const clientVersion = 'v5.0-ninja'
      const {stripeToken} = options
      let referrer = 'not set'
      try {
        referrer = document.referrer
      } catch (e) { console.log(e) }
      let order = {
        items: this.state.products.map(p => pick(p,
          ['id', 'sub', 'name', 'price', 'modgroups', 'customerPicks', 'photo', 'note', 'productId', 'quantity', 'tags'])),
        phone: this.state.phone,
        cartType: this.state.cartType || 'n/a',
        appliedCashback: this.state.appliedCashback,
        deliveryAddress: this.state.deliveryAddress,
        addressComponents: this.state.addressComponents,
        distanceInMiles: this.state.distanceInMiles,
        paymentType: this.state.paymentType,
        name: this.state.name,
        unitNumber: this.state.unitNumber,
        deliveryInstructions: this.state.deliveryInstructions,
        pickupTime: this.state.pickupTime,
        deliveryTime: this.state.deliveryTime,
        r_id: this.shopId,
        paymentType: this.state.paymentType || 'CARD',
        stripeToken,
        totals: this.getSubtotals(),
        analytics: this.state.analytics,
        referrer,
        promoCode: this.state.promoCode || '',
        clientVersion,
        dineInOption: this.state.dineInOption || '',
        tabNumber: this.state.tabNumber || '',
        utensilsOption: this.state.utensilsOption || '',
        restaurantDonationCents: this.state.restaurantDonationCents || 0,
        agreeToDonate: this.state.agreeToDonate || false,
      }
      var request = new XMLHttpRequest();
      request.open('POST', ORDERS_END_POINT, true);
      request.setRequestHeader('Content-Type', 'application/json');
      request.onload = () => {
        if (request.status == 200) {
          // Success!
          var response = JSON.parse(request.responseText);
          // console.log('orderId:', orderId)
          callback({success: true, data: response.text})
          this.clearCart()
        } else {
          var resp = JSON.parse(request.responseText);
          console.log(resp)
          callback({success: false, text: resp})
          // We reached our target server, but it returned an error

        }
      }
      request.onerror = function () {
        callback(false)
      }

      request.send(JSON.stringify(order));
      logEvent(`create`)
    }
    this.archiveOrder = () => {

    }
    this.removeFromCart = async (cartItemId) => {
      console.log('called removal', cartItemId)
      await this.setState({ products: this.state.products.filter(i => i.id != cartItemId) })
      this.saveCart()
      logEvent(`remove/${cartItemId}`)
    }
    this.editNote = async (note, cartId) => {

      await this.setState({
        products: this.state.products.map((p) => {
          if (p.id == cartId) {
            return Object.assign({}, p, {
              note: note
            })
          } else { return p }
        })
      })
      this.saveCart()
      // logEvent('note', note)
      // console.log('saved note')
    }
    this.setPromoCode = async (promoCode) => {
      await this.setState({ promoCode })
      this.saveCart()
    }
    this.editQuantity = async (value, cartItemId) => {
      await this.setState({
        products: this.state.products.map((p) => {
          if (p.id == cartItemId) {
            let newQuantity = (Number(p.quantity) || 0) + (Number(value) || 0)
            if (newQuantity < 1) { newQuantity = 1}
            return Object.assign({}, p, {
              quantity: newQuantity
            })
          } else { return p }
        })
      })
      this.saveCart()
    }
    this.editCustomerChoice = (newPicks, cartItemId) => {

      this.setStateAndSave({
        products: this.state.products.map((p) => {
          if (p.id == cartItemId) {
            return Object.assign({}, p, {
              customerPicks: Object.assign({}, p.customerPicks, newPicks)
            })
          } else { return p }
        })
      })
      // this.saveCart()
      try { logEvent(`mod/${cartItemId}`, JSON.stringify(newPicks)) } catch (e) {}
    }
    this.showItemCardForId = (id) => {
      if (id) {
        this.setState({ card: id })
      }
    }
    this.getCard = () => {
      return this.state.products.find(i => i.id == this.state.card)
    }
    this.getCartItemById = (id) => {
      return this.state.products.find(i => i.id == id)
    }
    this.closeCartItemCard = () => {
      this.setState({card: null})
    }
    this.getCartType = () => {

    }
    this.getSubtotals = () => {
      const settings = this.settings
      let numberOfMarketPriceItems = 0
      const TAX_MULT = Number(settings.taxPercentage) ?
        (Number(settings.taxPercentage) / 100)
        : (9.5 / 100)
      let taxTotal = 0
      let totalPrice = this.state.products.reduce((acc, cur) => {
        let {totalPrice: price, productTax} = calcItemPriceArray(cur, TAX_MULT)
        if (Number(productTax)) { taxTotal+=productTax }
        if (Number(price)) {
          return(Number(price) + acc)
        } else {
          numberOfMarketPriceItems+= 1

          return acc
        }
      }, 0)
      totalPrice = round(totalPrice, 2)
      let deliveryFeeInCents = 0
      let discount = (this.state.appliedCashback / 100) || 0
      let agreeToDonate = this.state.agreeToDonate || false
      let restaurantDonationCents = this.state.restaurantDonationCents || 199
      if (this.state.cartType === 'DELIVERY') {
        deliveryFeeInCents = this.state.deliveryFeeInCents || 0
       }
      // urban promo temp//
      if (this.state.promoFreeDelivery && (this.state.cartType === 'DELIVERY')) {
        discount = Number(discount) + Number(deliveryFeeInCents / 100)
        console.log('discount,', discount)
      }
      // end urban promo temp
      let promoDiscount = calcPromoDiscount({
        shopId: this.shopId,
        products: this.state.products,
        cartType: this.state.cartType,
        promoCode: this.state.promoCode,
        totalPrice: totalPrice,
        taxTotal: taxTotal,
        // invoice: invoice
      })
      if (Number(promoDiscount)) {
        console.log('🈹' + promoDiscount)
        discount = Number(discount) + Number(promoDiscount)
      }

      let subtotal = round((totalPrice - discount + deliveryFeeInCents / 100), 2)
      let deliveryTax = deliveryFeeInCents / 100 * TAX_MULT
      taxTotal += deliveryTax
      let tax = round(taxTotal, 2)
      let allServiceFee = Number(settings.allServicePercentage) ?
        round(subtotal * (Number(settings.allServicePercentage) / 100), 2)
        : 0
      let preTipPrimaryCents = Number(this.state.preTipPrimaryCents) || 0
      if (this.state.paymentType === 'CASH') {
        preTipPrimaryCents = 0
      }
      let preTipTotalWithTax = round((subtotal + tax + allServiceFee), 2)

      if (agreeToDonate) {
        preTipTotalWithTax = preTipTotalWithTax + restaurantDonationCents / 100
      }

      let invoice =            round((preTipTotalWithTax + preTipPrimaryCents/100), 2)

   

      let totals = {
        numberOfMarketPriceItems,
        totalPrice,
        discount,
        deliveryFeeInCents,
        subtotal,
        tax,
        allServiceFee,
        preTipTotalWithTax,
        invoice,
        preTipPrimaryCents,
        agreeToDonate,
        restaurantDonationCents
      }
      // console.log('totals:', totals)
      return totals
    }
    this.setDeliveryInfo = (props) => {
      // console.log('set delivery info:', {address})
      this.setStateAndSave(props)

      // this.saveCart()
      // try { logEvent(`setDelivery`, JSON.stringify(props)) } catch (e) {}
    }

    try {
      this.state =
        (window.localStorage[this.getStorageName()] &&
        JSON.parse(window.localStorage[this.getStorageName()])) || defaultState

      this.enforceRulesAndSave()

      if (this.state.phone.length > 5) {
        this.tryFetchAccount({phone: this.state.phone})
      }
    } catch (e) {
      console.log(e)
    }

    console.log('loaded state', this.state)

    this.saveQueryString()
  }

  render () {
    return('hi')
  }
}

export function injectCartContainer (Child) {
  return (props) => (
    <Subscribe to={[CartContainer]}>{(cart) => (<Child {...props} cart={cart} />)}</Subscribe>
  )
}
export default CartContainer
